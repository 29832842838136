
import Vue from "vue"
import { CustomerEnum, ClientEnum } from "@/types/enums"
import { Person, ReassignPersonRequest, ReassignClientPersonRequest, SnackbarInfo, ReassignTarget, AclExtract } from "@/types/responses"
import { required } from "vee-validate/dist/rules"
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from "vee-validate"

export default Vue.extend({
	components: {
	},
	async mounted() {
		await this.getCustomerClients()
	},
	name: "ReassignPersonDialog",
	props: {
		reassign: {
			type: Boolean,
		},
		person: {
			type: Object,
		},
        clientPersons: {
            type: Boolean,
            required: true
        },
        isLastMember: {
            type: Boolean
        }
	},
	data(): {
		customerenm: typeof CustomerEnum
		clientenm: typeof ClientEnum

		snackBar: boolean
		snackBarText: string
		loading: boolean
		timeLeft: number

		selectedClientId: null | string

		radioGroup: number
		newClient: string
		rules: unknown
	} {
		return {
			customerenm: CustomerEnum,
			clientenm: ClientEnum,

			snackBar: false,
			snackBarText: "",
			loading: false,
			timeLeft: 2,

			selectedClientId: null,

			radioGroup: 1,
			newClient: "",
			rules: {
				required: (value: string) => !!value || 'This field cannot be empty',
				counter: (value: string) => value.length <= 60 || 'Max 60 characters'
			}
		}
	},
	computed: {
		getAcl(): AclExtract {
			if(this.$route.name?.includes(this.customerenm.Customer)){
				return this.$store.state.acl[this.customerenm.Customer]
			}
			return this.$store.state.acl[this.clientenm.ClientOrganization]
		},
		enm(): typeof CustomerEnum | typeof ClientEnum {
			if(this.$route.name?.includes(this.customerenm.Customer)){
				return this.customerenm
			}

			return this.clientenm
		},
		toExistingAcl(): boolean{
			if(
				this.$route.name?.includes(this.customerenm.Customer) && !this.clientPersons &&
				this.$vStore.state.acl[this.customerenm.Customer].ui.includes(this.customerenm.ReassignPersonsToExisting)){
				return true
			} else if (
				this.$route.name?.includes(this.customerenm.Customer) && this.clientPersons &&
				this.$vStore.state.acl[this.customerenm.Customer].ui.includes(this.customerenm.ClientReassignPersonsToExisting)){
				return true
			}

			return this.$vStore.state.acl[this.clientenm.ClientOrganization].ui.includes(this.clientenm.ClientReassignPersonsToExisting)
		},
		toNewAcl(): boolean{
			if(
				this.$route.name?.includes(this.customerenm.Customer) && !this.clientPersons &&
				this.$vStore.state.acl[this.customerenm.Customer].ui.includes(this.customerenm.ReassignPersonsToNew)){
				return true
			} else if (
				this.$route.name?.includes(this.customerenm.Customer) && this.clientPersons &&
				this.$vStore.state.acl[this.customerenm.Customer].ui.includes(this.customerenm.ClientReassignPersonsToNew)){
				return true
			}

			return this.$vStore.state.acl[this.clientenm.ClientOrganization].ui.includes(this.clientenm.ClientReassignPersonsToNew)
		},
		toCompanyAcl(): boolean{
			if(
				this.$route.name?.includes(this.customerenm.Customer) && !this.clientPersons &&
				this.$vStore.state.acl[this.customerenm.Customer].ui.includes(this.customerenm.ReassignPersonsToCompany)){
				return true
			} else if (
				this.$route.name?.includes(this.customerenm.Customer) && this.clientPersons &&
				this.$vStore.state.acl[this.customerenm.Customer].ui.includes(this.customerenm.ClientReassignPersonsToCompany)){
				return true
			}
			
			return this.$vStore.state.acl[this.clientenm.ClientOrganization].ui.includes(this.clientenm.ClientReassignPersonsToCompany)
		},
		customerClientsComplete(): Array<{ text: string, value: null|string}> {
			const arr: Array<{ text: string, value: null|string}> = []
			arr.push({ text: "NONE", value: null})
			// eslint-disable-next-line
			const clients = this.$vStore.state.context.customerClients
			.sort(function(a, b) {
				const obj1 = a['id'].toUpperCase()
				const obj2 = b['id'].toUpperCase()
				if (obj1 < obj2) return -1
				if (obj1 > obj2) return 1
				return 0
			})
			.map(client => {
				return { text: client.name, value: client.id}
			})
			const selectList = [...arr,...clients]
			return selectList
		},
	},
	methods: {
		disableReassignButton(): boolean {
			if(this.loading) return true

			if(this.radioGroup == 1){
				if(this.selectedClientId == null) return true
			} else if (this.radioGroup == 2){
				if(this.newClient.length < 1) return true 
			}

			return false
		},
		getTargetClient(): ReassignTarget {
			if(this.radioGroup == 1){
				return {
					id: this.selectedClientId + "",
					new: false
				}
			} else if (this.radioGroup == 2){
				return {
					id: this.newClient,
					new: true
				}
			} else if (this.radioGroup == 3){
				return {
					id: this.newClient,
					new: false
				}
			} else {
				return {
					id: "",
					new: false
				}
			}
		},
		async countDownTimer(): Promise<void> {
			this.timeLeft = 2
			return new Promise((resolve) => {
				const downloadTimer = setInterval(() => {
					this.timeLeft--
					if (this.timeLeft <= 0) {
						clearInterval(downloadTimer)
						resolve()
					}
				}, 1000)
			})
		},
		cancel(): void {
			if(!this.loading){
				this.$emit("close", null)
			}
		},

		async getCustomerClients(): Promise<void> {
			if (this.loading) return
			if (this.$vStore.state.context.customerClients.length > 0) return
			this.loading = true
			try {
				await this.$vStore.dispatch("context/updateCustomerClients")
				this.loading = false
			} catch (e) {
				console.warn("Could not find any customerClients for this customer", e)
			}
		},

        async reassignUser(person: Person, reassignTarget: ReassignTarget): Promise<void> {
            console.log("reassingUser triggered. Reasigning: ", person, " to: ", reassignTarget)

            if(this.clientPersons) await this.reassignClientPerson(person, reassignTarget)
            else await this.reassignCustomerPerson(person, reassignTarget)

        },
        async reassignClientPerson(person: Person, reassignTarget: ReassignTarget): Promise<void> {
			this.loading = true
			let snackbarInfo: SnackbarInfo;
            console.log("reassignClientPerson triggered.")
            if(this.isLastMember){

                console.log("Reasigning last member, client company will be removed.")
                this.$emit('removeLastMember', true)
            }
			try {
				if (!this.$vStore.state.context.selectedClient) throw new Error('No selected client').message = "There was an error while handling target client.";
				const ReassignClientPersonRequest: ReassignClientPersonRequest = {
					client: this.$vStore.state.context.selectedClient,
					person: person,
					targetOrganizationId: reassignTarget.id,
				}
				
				if(!reassignTarget.new){
					// To existing client
					await this.$vStore.dispatch("context/reassignClientPerson", ReassignClientPersonRequest)
				}
				else {
					// To new client
					await this.$vStore.dispatch("context/reassignClientPerson", ReassignClientPersonRequest)
				}

				await this.countDownTimer()
				this.$emit('silentRefresh', null)
				snackbarInfo = {
					success: true,
					msg: "Successfully reassigned " + this.person.displayName + ".",
					e: null
				}
			} catch (e) {
				console.warn(e)
				snackbarInfo = {
					success: false,
					msg: "Could not reassign person.",
					e: e
				}
			}

			this.loading = false
			this.$emit('showSnackbarInfo', snackbarInfo)
			this.cancel()
		},
		async reassignCustomerPerson(person: Person, reassignTarget: ReassignTarget): Promise<void> {

			this.loading = true
			let snackbarInfo: SnackbarInfo;
            if(this.isLastMember){
                console.log("Reasigning last member, client company will be removed.")
                this.$emit('removeLastUser', null)
            }
			try {
				const ReassignPersonRequest: ReassignPersonRequest = {
					person: person,
					targetClientId: reassignTarget.id,
					newClientName: null
				}
				if(!reassignTarget.new){
					// Existing client
					await this.$vStore.dispatch("context/reassignPerson", ReassignPersonRequest)
				} else {
					// New client
					ReassignPersonRequest.targetClientId = null
					ReassignPersonRequest.newClientName = this.newClient
					await this.$vStore.dispatch("context/reassignPerson", ReassignPersonRequest)
				}
				await this.$vStore.dispatch("context/resetClientPersons")


				await this.countDownTimer()
				this.$emit('silentRefresh', null)
				snackbarInfo = {
					success: true,
					msg: "Successfully reassigned " + this.person.displayName + ".",
					e: null
				}
			} catch (e) {
				console.warn(e)
				snackbarInfo = {
					success: false,
					msg: "Could not reassign person.",
					e: e
				}
			}

			this.loading = false
			this.$emit('showSnackbarInfo', snackbarInfo)
			this.cancel()
		},
	},
})
